<div class="container-fluid bg-sm-consult-documents mb-4 d-lg-none"></div>

<div class="container-fluid bg-consult-documents pb-5">
  <div class="container px-lg-0">
    <div class="row pt-content mb-5 mb-lg-0">
      <div class="col-12 col-lg-5 pr-lg-4">
        <div class="bg-white px-3 py-4 border-content-login">
          <h1 class="text-blue text-center f-24 f-lg-26 Gotham-bold mb-4">
            {{ "Bienvenido a nuestro" | translate }}
            <br class="d-none d-md-block" />
            {{ "sistema de consultas" | translate }}
          </h1>

          <app-query-form
            *ngIf="!isOtpFormVisible"
            (disabled)="disabledSubmitBtn = $event"
            (formSubmit)="onQueryFormSubmit($event)"
          >
            <button
              type="submit"
              [disabled]="disabledSubmitBtn"
              class="btn btn-primary mt-3 w-100 btn-login Gotham-bold"
            >
              {{ "Consultar" | translate }}
            </button>
          </app-query-form>

          <app-otp-form
            *ngIf="isOtpFormVisible"
            (disabled)="disabledSubmitBtn = $event"
            [email]="currentEmail"
          >
            <button
              type="submit"
              [disabled]="disabledSubmitBtn"
              class="btn btn-primary mt-3 w-100 btn-login Gotham-bold"
            >
              {{ "Verificar" | translate }}
            </button>
          </app-otp-form>
        </div>
      </div>
    </div>
  </div>
</div>
