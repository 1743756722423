export const getWhatsappLink = (phone = '') =>
  `https://api.whatsapp.com/send?phone=${phone}&text=¡Hola!%20Quiero%20más%20información%20sobre%20la%20asistencia%20de%20viaje%20de%20SPV%20Seguro%20Para%20Viaje`;

export const maskEmail = (email: string): string => {
  if (!email) return '';
  const [localPart, domain] = email.split('@');
  const maskedLocalPart = localPart.slice(0, 4) + '********';
  return `${maskedLocalPart}@${domain}`;
};

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const getStatus = (status: number) => {
  switch (status) {
    case 0:
      return { label: 'Activo', color: 'success' };
    case 1:
      return { label: 'Modificado', color: 'info' };
    case 2:
      return { label: 'Anulado', color: 'danger' };
    case 3:
      return { label: 'En espera', color: 'warning' };
    case 4:
      return { label: 'Venció', color: 'secondary' };
    case 5:
      return { label: 'En disputa', color: 'warning' };
    default:
      return { label: 'Desconocido', color: 'secondary' };
  }
};

export const getLangs = () => {
  return [
    {
      value: 'es',
      label: 'Español',
      icon: 'https://res.cloudinary.com/pervolare-org/image/upload/v1741751386/spv/sites/images/consult-documents/icon-es_cozfbm.svg',
    },
    {
      value: 'en',
      label: 'Inglés',
      icon: 'https://res.cloudinary.com/pervolare-org/image/upload/v1741751386/spv/sites/images/consult-documents/icon-en_vldt16.svg',
    },
    {
      value: 'pt',
      label: 'Portugués',
      icon: 'https://res.cloudinary.com/pervolare-org/image/upload/v1741751386/spv/sites/images/consult-documents/icon-pt_j51xx8.svg',
    },
  ];
};
