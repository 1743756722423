<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <p class="Gotham-bold f-16">
    {{ "¡Accede a tu información en dos sencillos pasos!" | translate }}
  </p>
  <p class="Gotham f-16">
    1. {{ "Introduce tu correo electrónico para iniciar" | translate }}
  </p>
  <input
    type="email"
    class="form-control input-content-login"
    maxlength="255"
    formControlName="email"
    [ngClass]="{
      'is-valid': email?.valid,
      'is-invalid': email?.invalid && email?.touched
    }"
    [placeholder]="'Correo electrónico' | translate"
  />
  <div class="invalid-feedback">
    <span *ngIf="email?.errors?.['required']">
      {{ "El correo es obligatorio" | translate }}
    </span>
    <span *ngIf="email?.errors?.['pattern']">
      {{ "Ingresa un correo válido" | translate }}
    </span>
  </div>

  <!-- Load Submit Button -->
  <ng-content></ng-content>

  <hr />

  <p class="text-center Gotham f-14 mb-1">
    {{ "¿No pudiste acceder a tu certificado?" | translate }}
  </p>
  <p class="text-center Gotham-bold text-blue f-14 mb-0">
    <u>{{ "Olvidaste tu electrónico registrado" | translate }}</u>
  </p>
</form>
