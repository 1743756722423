import { Component } from '@angular/core';

@Component({
  selector: 'app-otp-form',
  templateUrl: './otp-form.component.html',
  styleUrls: ['./otp-form.component.scss'],
})
export class OtpFormComponent {
  public email: string = 'prueba@gmail.com';

  maskEmail(email: string): string {
    if (!email) return '';
    const [localPart, domain] = email.split('@');
    const maskedLocalPart = localPart.slice(0, 4) + '********';
    return `${maskedLocalPart}@${domain}`;
  }
}
