import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { I18nService } from './services/i18n';
import { environment } from '@env/environment';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit, OnDestroy {
  private _i18n = inject(I18nService);

  ngOnInit(): void {
    // Setup translations
    this._i18n.init(
      environment.defaultLanguage,
      environment.supportedLanguages
    );
  }

  ngOnDestroy(): void {
    this._i18n.destroy();
  }
}
