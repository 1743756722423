import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const culture =
      localStorage.getItem('language') ?? environment.defaultLanguage;

    request = request.clone({
      setHeaders: {
        Authentication: `${environment.apiToken}`,
        '.AspNetCore.Culture': `${culture}`,
      },
    });

    return next.handle(request);
  }
}
