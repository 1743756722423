import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VerifyOtpRequest, VerifyOtpResponse } from '@app/interfaces';

const routes = {
  verify: '/Otp/Verify',
  resend: '/Otp/Resend',
};

@Injectable({
  providedIn: 'root',
})
export class OtpService {
  private http = inject(HttpClient);

  verify(data: VerifyOtpRequest): Observable<VerifyOtpResponse> {
    return this.http.post<VerifyOtpResponse>(routes.verify, data);
  }

  resend(email: string): Observable<boolean> {
    return this.http.post<boolean>(routes.resend, { email });
  }
}
