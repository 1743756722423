import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CertificateEmailRelatedResponse,
  CertificateSendByMailRequest,
} from '@app/interfaces';

const routes = {
  emailValidate: '/Certificate/Email/Validate',
  related: (email: string) => `/Certificate/Email/Related/${email}`,
  sendByEmail: (lang: string, email: string) =>
    `/Certificate/SendByMail/${lang}/${email}`,
};

@Injectable({
  providedIn: 'root',
})
export class CertificateService {
  private http = inject(HttpClient);

  emailValidate(email: string): Observable<boolean> {
    return this.http.post<boolean>(routes.emailValidate, { email });
  }

  getCertificate(
    email: string,
    token: string
  ): Observable<CertificateEmailRelatedResponse> {
    return this.http.get<CertificateEmailRelatedResponse>(
      routes.related(email),
      { headers: { 'X-Api-Token': token } }
    );
  }

  sendByEmail(
    { lang, email, ...params }: CertificateSendByMailRequest,
    token: string
  ): Observable<boolean> {
    return this.http.get<boolean>(routes.sendByEmail(lang, email), {
      params,
      headers: { 'X-Api-Token': token },
    });
  }
}
