import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${environment.deployUrl}/assets/i18n/`,
    '.json'
  );
}
