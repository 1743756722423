export const environment = {
  production: false,
  defaultLanguage: 'es',
  supportedLanguages: ['es', 'en', 'pt'],
  tenantId: 2,
  apiUrl: 'https://spv-api-qa-temp.pervolare.net',
  apiToken:
    'c3B2LTItUEFnOVRmWjg5UVZqWjVZSDhVWkZoc3RrRHBHbmNkSkNxUUI4R2NTc1NxbXVrYjM0R0RBQVVETDlDNDdGN0Y4bg==',
  deployUrl: 'https://web-spv-qa-temp-microfrontend.pervolare.net/checkout',
};
