import { Component } from '@angular/core';

@Component({
  selector: 'app-document-query',
  templateUrl: './document-query.component.html',
  styleUrls: ['./document-query.component.scss'],
})
export class DocumentQueryComponent {
  public isOtpFormVisible = false;
  public disabledSubmitBtn = false;

  onQueryFormSubmit() {
    // Aquí iría la lógica para enviar el correo o hacer la consulta
    // Una vez se complete el envío, cambiamos el estado
    this.isOtpFormVisible = true;
  }

  onOtpFormSubmit() {
    // Aquí iría la lógica para validar el código OTP
    console.log('OTP Form Submitted');
  }
}
