import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Alert,
  ErrorAlert,
  getLangs,
  getStatus,
  isValidEmail,
} from '@app/helpers';
import { CertificateItem, CertificateSendByMailRequest } from '@app/interfaces';
import { Lang } from '@app/interfaces/lang.interface';
import { CertificateService } from '@app/services';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-document-results',
  templateUrl: './document-results.component.html',
  styleUrls: ['./document-results.component.scss'],
})
export class DocumentResultsComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private _certificate = inject(CertificateService);

  private token = this.route.snapshot.queryParamMap.get('t') ?? '';
  public email: string = atob(this.route.snapshot.params['email']);
  public langs = getLangs();
  public status = getStatus;
  public activeTab = '';
  public isCollapsed = true;
  public currentLang: Lang | null = null;
  public loadingResend = false;
  public allCertificates: CertificateItem[] = [];
  public currentCertificate: CertificateItem | null = null;

  get displayName() {
    return this.currentCertificate?.name ?? this.allCertificates[0]?.name ?? '';
  }

  ngOnInit(): void {
    if (!this.token && !isValidEmail(this.email)) {
      this.router.navigate(['document-consultation']);
    }

    this.currentLang =
      this.langs.find(
        ({ value }) => value === localStorage.getItem('language')?.split('-')[0]
      ) ?? this.langs[0];
    this.loadCertificate();
  }

  resendEmail() {
    this.loadingResend = true;

    const body: CertificateSendByMailRequest = {
      email: this.email,
      lang: this.currentLang?.value ?? 'es',
    };

    if (this.currentCertificate?.isMainCustomer) {
      body.quotationId = this.currentCertificate?.quotationId;
    } else {
      body.purchaseNumber = this.currentCertificate?.code;
    }

    this._certificate
      .sendByEmail(body, this.token)
      .pipe(finalize(() => (this.loadingResend = false)))
      .subscribe({
        next: () =>
          Alert.fire(
            '¡Correo enviado!',
            `Se ha enviado la documentación al correo ${this.email}`,
            'success'
          ),
        error: () => ErrorAlert.fire('¡Error al enviar el correo!'),
      });
  }

  private loadCertificate() {
    this._certificate.getCertificate(this.email, this.token).subscribe({
      next: (res) => {
        this.currentCertificate =
          res.current.length > 0 ? res.current[0] : null;
        this.allCertificates = res.actives
          .concat(res.expired)
          .concat(res.inDispute)
          .concat(res.nulled)
          .concat(res.standBy)
          .sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );

        this.activeTab = this.currentCertificate ? 'home' : 'purchases-history';
      },
      error: () => this.router.navigate(['document-consultation']),
    });
  }
}
