import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';
import { ApiInterceptor } from './api.interceptor';
import { ApiPrefixInterceptor } from './api-prefix.interceptor';
import { ApiParseResultInterceptor } from './api-parse-result.interceptor';

export const INTERCEPTORS_PROVIDER: Provider[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiPrefixInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiParseResultInterceptor,
    multi: true,
  },
];
