import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DocumentQueryComponent } from './features/document-query/document-query.component';
import { DocumentResultsComponent } from './features/document-results/document-results.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from './services/i18n';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { INTERCEPTORS_PROVIDER } from './interceptors';
import { QueryFormComponent } from './features/document-query/components/query-form/query-form.component';
import { OtpFormComponent } from './features/document-query/components/otp-form/otp-form.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    DocumentQueryComponent,
    DocumentResultsComponent,
    QueryFormComponent,
    OtpFormComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [...INTERCEPTORS_PROVIDER],
  bootstrap: [AppComponent],
})
export class AppModule {}
