import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    request = request.clone({
      setHeaders: {
        Authentication: `${environment.apiToken}`,
        'Accept-Language': this.getLanguage(),
      },
    });

    return next.handle(request);
  }

  private getLanguage(): string {
    const language =
      localStorage.getItem('language') ?? environment.defaultLanguage;
    return language.toLowerCase().split('-')[0];
  }
}
