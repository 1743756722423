import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/* This class intercepts all HTTP requests and if the response is an instance of HttpResponse, it
clones the response and returns the body of the response as the result property of the body. */
@Injectable()
export class ApiParseResultInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const modEvent = event.clone({ body: event.body['result'] });

          return modEvent;
        }

        return event;
      }),
      catchError((error: HttpErrorResponse) =>
        throwError(() => error.error?.result)
      )
    );
  }
}
