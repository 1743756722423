import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { DocumentQueryComponent } from './features/document-query/document-query.component';
import { DocumentResultsComponent } from './features/document-results/document-results.component';
import { APP_BASE_HREF } from '@angular/common';

const routes: Routes = [
  { path: 'document-consultation', component: DocumentQueryComponent },
  {
    path: 'document-consultation/docs/:email',
    component: DocumentResultsComponent,
  },
  // Fallback when no prior route is matched
  { path: '**', component: EmptyRouteComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
})
export class AppRoutingModule {}
