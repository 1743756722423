<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <p class="Gotham-bold f-16">
    2. {{ "Revisa tu correo electrónico" | translate }}
  </p>
  <p class="Gotham f-16">
    {{ "Hemos enviado un código de confirmación de seis digitos." | translate }}
  </p>
  <p class="Gotham f-16">
    {{ "No olvides ingresar el código lo antes posible." | translate }}
  </p>
  <p class="Gotham f-16">{{ "Correo electrónico" | translate }}:</p>
  <p class="Gotham f-16">{{ maskEmail(email) }}</p>
  <button
    *ngIf="timeRemaining === 0"
    (click)="resend()"
    type="button"
    class="bg-white text-decoration-underline text-blue Gotham-bold f-16 my-4 p-0 border-0"
  >
    {{ "Reenviar." | translate }}&nbsp;{{
      "No recibí el código de seguridad" | translate
    }}
  </button>
  <div
    *ngIf="timeRemaining > 0"
    class="d-flex w-100 align-items-center py-3 justify-content-center content-load mb-4"
  >
    <img
      class="d-none d-md-block mr-2"
      src="https://res.cloudinary.com/pervolare-org/image/upload/v1726090183/spv/sites/images/consult-documents/icon-reload_xyg3rn.svg"
      alt="icon load"
    />
    <p class="f-16 mb-0">
      {{ "Solicitalo nuevamente en" | translate }}&nbsp;{{
        timeRemaining
      }}&nbsp;{{ "segundos" | translate }}
    </p>
  </div>
  <p class="Gotham f-16">
    {{ "Código de verificación" | translate }}
  </p>
  <input
    type="text"
    class="form-control input-content-login"
    minlength="6"
    maxlength="6"
    [ngClass]="{
      'is-valid': code?.valid,
      'is-invalid': code?.invalid && code?.touched
    }"
    formControlName="code"
    [placeholder]="'Código de verificación' | translate"
  />

  <!-- Load Submit Button -->
  <ng-content></ng-content>

  <hr />

  <p class="text-center Gotham f-14 mb-1">
    {{
      "Si tu correo electrónico no está actualizado, comunícate con nuestra central de asistencia haciendo Clic"
        | translate
    }}
    <a href="#" class="text-blue"
      ><u>{{ "aqui" | translate }}</u></a
    >
  </p>
</form>
