import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-query-form',
  templateUrl: './query-form.component.html',
  styleUrls: ['./query-form.component.scss'],
})
export class QueryFormComponent {
  @Output() disabled = new EventEmitter<boolean>(false);
  @Output() formSubmit = new EventEmitter<void>();

  public form = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.maxLength(255),
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),
  });

  get email() {
    return this.form.get('email');
  }

  onSubmit() {
    this.disabled.emit(true);

    if (this.form.valid) {
      setTimeout(() => {
        this.formSubmit.emit();
        this.disabled.emit(false);
      }, 300);

      // Lógica adicional (como una llamada a la API) puede ir aquí
    } else {
      setTimeout(() => this.disabled.emit(false), 300);
      alert('Formulario invalido');
    }
  }
}
