<div class="container-fluid bg-view-document">
  <div class="container pt-5 center-xs start-lg pl-lg-5">
    <h1 class="fw-700 Gotham f-30 f-lg-40 text-white mt-1 mb-4 f-30-bg">
      ¡{{ "Hola" | translate }}, Elsa Orozco!
    </h1>
    <h2
      class="fw-400 Gotham f-16 f-lg-32 text-white f-23-bg"
      [innerHTML]="'Descripcion fondo' | translate"
    ></h2>
  </div>
</div>

<div class="container px-md-0 mb-2 mt-3">
  <nav ngbNav #nav="ngbNav" class="nav-tabs" id="mat-card">
    <ng-container ngbNavItem>
      <a
        class="Gotham f-20"
        style="font-weight: 400"
        ngbNavLink
        (click)="activeTab = 'Inicio'"
      >
        <img
          class="d-none icon-tab-active"
          src="https://res.cloudinary.com/pervolare-org/image/upload/v1728447194/spv/sites/images/consult-documents/home-white_xxhpxz.png"
          alt="icon"
        />
        <img
          class="icon-tab"
          src="https://res.cloudinary.com/pervolare-org/image/upload/v1728447206/spv/sites/images/consult-documents/home-black_tpq5bd.png"
          alt="icon"
        />
        {{ "Inicio" | translate }}
      </a>
      <ng-template ngbNavContent>
        <p
          class="text-lh-blue fw-700 Gotham f-20 f-lg-25 center-xs start-lg mt-3"
        >
          {{ "Este es el estado de tu asistencia" | translate }}
        </p>
        <div class="container">
          <div class="row mt-3 content-border mb-3">
            <div class="col-6 col-md-6 col-lg pl-lg-0 pr-1 mb-3 mb-lg-0">
              <div class="status-card pt-1 pb-3 py-md-3 px-2">
                <div
                  class="d-flex align-items-center justify-content-center mb-3"
                >
                  <img
                    class="mr-2"
                    src="https://res.cloudinary.com/pervolare-org/image/upload/v1726274846/spv/sites/images/consult-documents/icon-certificate_qtg7ux.svg"
                    alt="icono nuemero de certificado"
                  />
                  <p
                    class="tatus-text-color Gotham-bold f-14 mb-0 f-dinami"
                    [innerHTML]="'Certificado No.' | translate"
                  ></p>
                </div>
                <p class="Gotham f-14 status-text-color mb-0 f-dinami">
                  1067500
                </p>
              </div>
            </div>

            <div class="col-6 col-md-6 col-lg pr-1 mb-3 mb-lg-0">
              <div
                class="status-card pt-1 pb-3 py-md-3 px-2 d-flex flex-column justify-content-between d-md-block"
              >
                <div
                  class="d-flex align-items-center justify-content-center mb-3"
                >
                  <img
                    class="mr-2"
                    src="https://res.cloudinary.com/pervolare-org/image/upload/v1726274846/spv/sites/images/consult-documents/icon-name_dei8em.svg"
                    alt="icon name"
                  />
                  <p class="status-text-color Gotham-bold f-14 mb-0">
                    {{ "Nombre" | translate }}
                  </p>
                </div>
                <p
                  class="Gotham f-14 status-text-color mb-0 f-dinami mt-auto d-block d-md-none"
                >
                  Elsa Lopez
                </p>
                <p
                  class="Gotham f-14 status-text-color mb-0 f-dinami d-none d-md-block"
                >
                  Elsa Lopez
                </p>
              </div>
            </div>

            <div class="col-6 col-md-6 col-lg pr-1 mb-3 mb-lg-0">
              <div class="status-card pt-1 pb-3 py-md-3 px-2">
                <div
                  class="d-flex align-items-center justify-content-center mb-3"
                >
                  <img
                    class="mr-2"
                    src="https://res.cloudinary.com/pervolare-org/image/upload/v1726274846/spv/sites/images/consult-documents/icon-destiny_bzqekb.svg"
                    alt="icono destino de viaje"
                  />
                  <p
                    class="tatus-text-color Gotham-bold f-14 mb-0 f-dinami"
                    [innerHTML]="'Destino de viaje' | translate"
                  ></p>
                </div>
                <p class="Gotham f-14 status-text-color mb-0 f-dinami">
                  Europa
                </p>
              </div>
            </div>

            <div
              class="col-12 col-md-12 col-lg pr-1 mb-3 mb-lg-0 order-2 order-lg-1"
            >
              <div class="status-card py-3 px-2">
                <div
                  class="d-flex align-items-center justify-content-center mb-3"
                >
                  <img
                    class="mr-2"
                    src="https://res.cloudinary.com/pervolare-org/image/upload/v1726274846/spv/sites/images/consult-documents/icon-plan_ztbd3q.svg"
                    alt="icono plan contratado"
                  />
                  <p class="tatus-text-color Gotham-bold f-14 mb-0 f-dinami">
                    {{ "Plan contratado" | translate }}
                  </p>
                </div>
                <p class="Gotham f-14 status-text-color mb-0 f-dinami">
                  Esencial
                </p>
              </div>
            </div>

            <div
              class="col-6 col-md-6 col-lg pr-1 mb-3 mb-lg-0 order-1 order-lg-2"
            >
              <div class="status-card pt-1 pb-3 py-md-3 px-2">
                <div
                  class="d-flex align-items-center justify-content-center mb-3"
                >
                  <img
                    class="mr-2"
                    src="https://res.cloudinary.com/pervolare-org/image/upload/v1726274846/spv/sites/images/consult-documents/icon-status_grbup2.svg"
                    alt="icono estado de certificado"
                  />
                  <p
                    class="tatus-text-color Gotham-bold f-14 mb-0 f-dinami"
                    [innerHTML]="'Estado de certificado' | translate"
                  ></p>
                </div>
                <p
                  class="Gotham-bold f-14 mb-0 f-dinami active-status-certificate"
                >
                  Activo
                </p>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        class="Gotham f-16 f-lg-20"
        style="font-weight: 400"
        ngbNavLink
        (click)="activeTab = 'Historial de compras'"
      >
        <img
          class="d-none icon-tab-active"
          src="https://res.cloudinary.com/pervolare-org/image/upload/v1728447218/spv/sites/images/consult-documents/h-white_gnqtrx.png"
          alt="icon"
        />
        <img
          class="icon-tab"
          src="https://res.cloudinary.com/pervolare-org/image/upload/v1728445935/spv/sites/images/consult-documents/h-black_edijwy.png"
          alt="icon"
        />
        {{ "Historial de compras" | translate }}
      </a>
      <ng-template ngbNavContent>
        <p class="text-lh-blue Gotham f-20 f-lg-25 center-xs mt-3">
          <span class="fw-700">{{
            "¡Bienvenido de vuelta, viajero!" | translate
          }}</span>
          {{
            "Aquí puedes revisar tu historial de certificados de asistencia"
              | translate
          }}
        </p>
        <div class="container">
          <div class="row mt-3 content-border mb-3">
            <div class="col-6 col-md-6 col-lg pl-lg-0 pr-1 mb-3 mb-lg-0">
              <div class="status-card pt-1 pb-3 py-md-3 px-2">
                <div
                  class="d-flex align-items-center justify-content-center mb-3"
                >
                  <img
                    class="mr-2"
                    src="https://res.cloudinary.com/pervolare-org/image/upload/v1726274846/spv/sites/images/consult-documents/icon-certificate_qtg7ux.svg"
                    alt="icono nuemero de certificado"
                  />
                  <p
                    class="tatus-text-color Gotham-bold f-14 mb-0 f-dinami"
                    [innerHTML]="'Certificado No.' | translate"
                  ></p>
                </div>
                <p class="Gotham f-14 status-text-color mb-0 f-dinami">
                  1067500
                </p>
              </div>
            </div>

            <div class="col-6 col-md-6 col-lg pr-1 mb-3 mb-lg-0">
              <div
                class="status-card pt-1 pb-3 py-md-3 px-2 d-flex flex-column justify-content-between d-md-block"
              >
                <div
                  class="d-flex align-items-center justify-content-center mb-3"
                >
                  <img
                    class="mr-2"
                    src="https://res.cloudinary.com/pervolare-org/image/upload/v1726274846/spv/sites/images/consult-documents/icon-name_dei8em.svg"
                    alt="icon name"
                  />
                  <p class="status-text-color Gotham-bold f-14 mb-0">
                    {{ "Nombre" | translate }}
                  </p>
                </div>
                <p
                  class="Gotham f-14 status-text-color mb-0 f-dinami mt-auto d-block d-md-none"
                >
                  Elsa Lopez
                </p>
                <p
                  class="Gotham f-14 status-text-color mb-0 f-dinami d-none d-md-block"
                >
                  Elsa Lopez
                </p>
              </div>
            </div>

            <div class="col-6 col-md-6 col-lg pr-1 mb-3 mb-lg-0">
              <div class="status-card pt-1 pb-3 py-md-3 px-2">
                <div
                  class="d-flex align-items-center justify-content-center mb-3"
                >
                  <img
                    class="mr-2"
                    src="https://res.cloudinary.com/pervolare-org/image/upload/v1726274846/spv/sites/images/consult-documents/icon-destiny_bzqekb.svg"
                    alt="icono destino de viaje"
                  />
                  <p
                    class="tatus-text-color Gotham-bold f-14 mb-0 f-dinami"
                    [innerHTML]="'Destino de viaje' | translate"
                  ></p>
                </div>
                <p class="Gotham f-14 status-text-color mb-0 f-dinami">
                  Europa
                </p>
              </div>
            </div>

            <div
              class="col-12 col-md-12 col-lg pr-1 mb-3 mb-lg-0 order-2 order-lg-1"
            >
              <div class="status-card py-3 px-2">
                <div
                  class="d-flex align-items-center justify-content-center mb-3"
                >
                  <img
                    class="mr-2"
                    src="https://res.cloudinary.com/pervolare-org/image/upload/v1726274846/spv/sites/images/consult-documents/icon-plan_ztbd3q.svg"
                    alt="icono plan contratado"
                  />
                  <p class="tatus-text-color Gotham-bold f-14 mb-0 f-dinami">
                    {{ "Plan contratado" | translate }}
                  </p>
                </div>
                <p class="Gotham f-14 status-text-color mb-0 f-dinami">
                  Esencial
                </p>
              </div>
            </div>

            <div
              class="col-6 col-md-6 col-lg pr-1 mb-3 mb-lg-0 order-1 order-lg-2"
            >
              <div class="status-card pt-1 pb-3 py-md-3 px-2">
                <div
                  class="d-flex align-items-center justify-content-center mb-3"
                >
                  <img
                    class="mr-2"
                    src="https://res.cloudinary.com/pervolare-org/image/upload/v1726274846/spv/sites/images/consult-documents/icon-status_grbup2.svg"
                    alt="icono estado de certificado"
                  />
                  <p
                    class="tatus-text-color Gotham-bold f-14 mb-0 f-dinami"
                    [innerHTML]="'Estado de certificado' | translate"
                  ></p>
                </div>
                <p
                  class="Gotham-bold f-14 mb-0 f-dinami not-active-status-certificate"
                >
                  Activo
                </p>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </nav>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

<div
  class="container-fluid p-data-document"
  style="background: #e8f1ff"
  *ngIf="activeTab !== 'Historial de compras'"
>
  <div
    class="container bg-white pt-3 text-center b-data-document h-content-document"
  >
    <p class="text-blue fw-700 Gotham f-20 f-lg-25">
      {{ "Recibe tu documentación de viaje" | translate }}:
    </p>
    <p class="Gotham f-20" style="color: #13141c">
      {{ "Reenviaremos tu documentación al siguiente correo" | translate }}:
    </p>
    <p class="Gotham-bold f-20" style="color: #13141c">acastro@gmail.com</p>
    <a class="text-blue f-16 fw-500 mt-4 d-block"
      ><u>{{
        "¿Necesitas que la documentación esté disponible en otro idioma?"
          | translate
      }}</u></a
    >
    <button class="mt-3 btn-document text-white btn">
      {{ "Reenviar" | translate }}
    </button>
  </div>
</div>
