import { Component } from '@angular/core';

@Component({
  selector: 'app-document-results',
  templateUrl: './document-results.component.html',
  styleUrls: ['./document-results.component.scss'],
})
export class DocumentResultsComponent {
  public activeTab: string = 'Inicio';
}
