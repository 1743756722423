import { Component, EventEmitter, inject, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorAlert, getWhatsappLink } from '@app/helpers';
import { CertificateService } from '@app/services';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-query-form',
  templateUrl: './query-form.component.html',
  styleUrls: ['./query-form.component.scss'],
})
export class QueryFormComponent {
  @Output() disabled = new EventEmitter<boolean>();
  @Output() formSubmit = new EventEmitter<string>();

  private _certificate = inject(CertificateService);

  public form = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.maxLength(255),
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),
  });

  get email() {
    return this.form.get('email');
  }

  onSubmit() {
    if (this.form.invalid) return;

    this.disabled.emit(true);

    const email = this.email?.value ?? '';
    this._certificate
      .emailValidate(email)
      .pipe(finalize(() => this.disabled.emit(false)))
      .subscribe({
        next: (result) => {
          if (result) {
            this.formSubmit.emit(email);
          } else {
            ErrorAlert.fire({
              text: 'El email que ingresaste no es correcto, para recibir ayuda de nuestro equipo comercial da clic aquí:',
            }).then((value) => {
              if (value.isConfirmed) {
                location.href = getWhatsappLink();
              }
            });
          }
        },
        error: () =>
          ErrorAlert.fire({
            text: 'El email ingresado no es valido',
            confirmButtonText: 'OK',
          }),
      });
  }
}
