import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  Alert,
  ErrorAlert,
  ErrorTopEndAlert,
  getWhatsappLink,
  maskEmail,
} from '@app/helpers';
import { OtpService } from '@app/services';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-otp-form',
  templateUrl: './otp-form.component.html',
  styleUrls: ['./otp-form.component.scss'],
})
export class OtpFormComponent implements OnInit, OnDestroy {
  @Input() public email: string = '';
  @Output() disabled = new EventEmitter<boolean>();
  @Output() formSubmit = new EventEmitter<string>();

  private _otp = inject(OtpService);
  private router = inject(Router);

  public maskEmail = maskEmail;
  public timeRemaining: number = 0;

  private attempts: number = 0;
  private lastSend: number = 0;
  private interval: number = 60000; // 1 minuto en milisegundos
  maxIntentos: number = 3;
  mensaje: string = '';
  timerInterval: any;

  public form = new FormGroup({
    code: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
    ]),
  });

  get code() {
    return this.form.get('code');
  }

  ngOnInit(): void {
    // Recuperar los datos del localStorage al iniciar el componente
    const datos = JSON.parse(localStorage.getItem('otpData') || '{}');
    this.attempts = datos.attempts || 0;
    this.lastSend = datos.lastSend || new Date().getTime();
    this.timeRemaining = this.interval / 1000;

    // Si hay tiempo restante en el intervalo, iniciar el contador
    if (this.lastSend && new Date().getTime() - this.lastSend < this.interval) {
      this.timeRemaining = Math.ceil(
        (this.interval - (new Date().getTime() - this.lastSend)) / 1000
      );
    }
    this.startTimer();
  }

  ngOnDestroy(): void {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }

  onSubmit() {
    if (this.form.invalid) return;
    this.disabled.emit(true);

    this._otp
      .verify({
        email: this.email,
        otp: this.code?.value ?? '',
      })
      .pipe(finalize(() => this.disabled.emit(false)))
      .subscribe({
        next: (result) => {
          if (result.validOtp) {
            this.cleanAttempts();
            this.router.navigate(
              ['document-consultation/docs', btoa(this.email)],
              { queryParams: { t: result.token } }
            );
          } else {
            ErrorAlert.fire({
              text: 'El código que ingresaste no es correcto, para recibir ayuda de nuestro equipo comercial da clic aquí:',
            }).then((value) => {
              if (value.isConfirmed) {
                location.href = getWhatsappLink();
              }
            });
          }
        },
        error: () =>
          ErrorAlert.fire({
            text: 'El código ingresado no es valido',
            confirmButtonText: 'OK',
          }),
      });
  }

  resend(): void {
    const ahora = new Date().getTime();

    // Verificamos si se alcanzó el límite de intentos
    if (this.attempts >= this.maxIntentos) {
      ErrorTopEndAlert.fire({
        text: 'Has alcanzado el límite de intentos por hora.',
        timer: 3000,
      });
      return;
    }

    // Verificamos si ha pasado el tiempo necesario (al menos 1 minuto)
    if (ahora - this.lastSend < this.interval) {
      const tiempoRestante = Math.ceil(
        (this.interval - (ahora - this.lastSend)) / 1000
      );
      this.mensaje = `Debes esperar ${tiempoRestante} segundos antes de solicitar otro código.`;
      this.timeRemaining = tiempoRestante;
      ErrorAlert.fire({
        text:
          'Debes esperar ' +
          this.timeRemaining +
          ' segundos antes de solicitar otro código.',
      });
      this.startTimer(); // Iniciar el contador si aún hay tiempo
      return;
    }

    // Si pasa las validaciones, se envía el código
    this._otp.resend(this.email).subscribe({
      next: () => {
        // Actualizar los datos en el localStorage
        this.attempts++;
        this.lastSend = ahora;
        localStorage.setItem(
          'otpData',
          JSON.stringify({ attempts: this.attempts, lastSend: this.lastSend })
        );

        // Mostrar mensaje de éxito
        Alert.fire({
          icon: 'success',
          title: 'Código enviado con éxito!',
        });
        this.timeRemaining = this.interval / 1000; // Restablecer el tiempo restante
        this.startTimer();
      },
    });
  }

  private startTimer(): void {
    if (this.timerInterval) {
      clearInterval(this.timerInterval); // Limpiar cualquier interval anterior
    }
    this.timerInterval = setInterval(() => {
      if (this.timeRemaining > 0) {
        this.timeRemaining--;
      } else {
        clearInterval(this.timerInterval);
        this.mensaje = 'Ya puedes solicitar un nuevo código.';
      }
    }, 1000); // Decrementa cada segundo
  }

  private cleanAttempts(): void {
    localStorage.removeItem('otpData');
    this.attempts = 0;
    this.lastSend = 0;
    this.mensaje = 'Los intentos han sido limpiados.';
    this.timeRemaining = 0; // Reiniciar el tiempo restante
  }
}
